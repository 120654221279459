import {
  ADD_COUPON,
  ADMIN_CLIENT_COMPANY,
  ADMIN_CLIENT_OWNER,
  ADMIN_CLIENT_OWNER_USERS,
  ADMIN_NOTIFICATION_DATA,
  ASSIGN_ASSISTANT,
  ASSISTANT,
  ATTACH_PAYMENT_METHOD,
  AUTH_TOKEN,
  AVAILABLE_ASSISTANTS,
  AVATAR,
  CALENDAR_ENTRIES,
  CALENDAR_ENTRY,
  CALENDAR_ENTRY_RECURRENCES,
  CALENDAR_ENTRY_REMINDER_TIME,
  CALENDAR_ENTRY_SEEN,
  CALENDAR_ENTRY_TYPES,
  CALENDAR_MONTHLY_AVAILABILITY,
  CALENDAR_NOTIFICATION_DATA,
  CALENDAR_USERS,
  CHANGE_DEFAULT_PAYMENT_METHOD,
  CHANGE_PASSWORD,
  CHAT_GROUPS_OVERSEEN,
  CHAT_MESSAGES,
  CHAT_ROOM_MESSAGES,
  CHAT_ROOM_MESSAGES_OVERSEEN,
  CHAT_ROOM_MESSAGES_OVERSEEN_ADDITIONAL,
  CLIENT,
  CLIENTS_FOR_ADMIN_CLIENT,
  COMPANY,
  CONFIRMED_REGISTRATIONS,
  CONFIRM_REGISTRATION,
  CREATE_USER,
  CREATE_USER_LIMIT,
  CREATE_USER_WITH_REGISTRATION_LINK,
  DELETE_GUEST_TASK,
  DELETE_PAYMENT_METHOD,
  DOCUMENT,
  DOCUMENTS,
  GET_EMAIL_INVITATIONS,
  GET_PAYMENT_METHODS,
  GET_STRIPE_CUSTOMER,
  GUEST_AVAILABLE_TASKS,
  GUEST_EXISTING,
  GUEST_TASK,
  GUEST_USER,
  LATEST_COMMENTS,
  LATEST_DOCUMENTS,
  LOGGED_USER,
  NEW_REGISTRATIONS,
  NOTIFICATION_DATA,
  NOTIFICATION_FREQUENCIES,
  PHONEBOOK_AC_COMPANY_CONTACTS,
  PHONEBOOK_CONTACT,
  PHONEBOOK_CONTACTS,
  PHONEBOOK_CONTACTS_DELETE,
  PHONEBOOK_CONTACT_DETAIL_TYPES,
  PHONEBOOK_USERS,
  PUSH_ON_CLIENT_DATA,
  PUSH_ON_SETTINGS,
  PUSH_ON_TASK,
  RECOVER_PASSWORD,
  REGISTER_USER_WITH_LINK,
  REGISTRATION,
  REJECTED_REGISTRATIONS,
  RESEND_REGISTRATION_LINK,
  RESET_PASSWORD,
  SETTINGS,
  SET_TASK_COMMENTS_SEEN,
  SPECIFIC_USER,
  TASK,
  TASKS,
  TASK_ASSIGNEE,
  TASK_AVAILABLE_USERS_FOR_AC_COMPANY,
  TASK_COMMENTS,
  TASK_COMMENT_NOTIFICATION_DATA,
  TASK_DOCUMENT_NOTIFICATION_DATA,
  TASK_NOTIFICATION_DATA,
  TASK_STATUS,
  TASK_STATUSES,
  UNASSIGN_ASSISTANT,
  UPDATE_REGISTRATION,
  USER,
  USER_ACTIVITY,
  USER_BASE,
  USER_DATA,
  USER_EMAIL,
  USER_SETTINGS,
  VALIDATE_DATA,
} from './api-routes';

import { API_BASE_URL } from '../pages/Products/api';
import { ApiError } from '../common/ApiError';
import { INTIAL_OVERSEEN_MESSAGE_COUNT_FETCH } from '../common/appConstants';
import axios from 'axios';
import { setAuthorizationToken } from '../utilities/api-helper';
import moment from 'moment';

export class LoginError extends Error {
  constructor(message, data) {
    super(message);
    this._data = data;
  }

  get data() {
    return this._data;
  }
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      return {
        data: {
          Success: false,
          StatusCode: error.response.status,
          Message: error.response.data.Message,
        },
      };
    } else {
      return Promise.reject(error);
    }
  }
);

export function generateToken(data) {
  const customer = JSON.parse(localStorage.getItem('customer'));

  return axios
    .post(AUTH_TOKEN, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((response) => {
      const token = response.data.access_token;
      localStorage.setItem('authToken', token);
      setAuthorizationToken(token);
      return { token, customer };
    })
    .catch((error) => {
      if (error.response) {
        const { data } = error.response;
        throw new LoginError(data.error_description, data);
      } else throw error;
    });
}

export async function loginCheck() {
  try {
    const response = await axios.get(`${API_BASE_URL}/LoginCheck`);
    return { status: response.status, msg: response.data.NextLoginPage, pendingInvoiceId: response?.data?.InvoiceId };
  } catch (e) {
    return { status: e.response.status, msg: e.response.data.Message };
  }
}

export async function getLoggedUser() {
  try {
    const response = await axios.get(LOGGED_USER + '/false');
    if (!response.data.Success) {
      if (response.data.StatusCode === 401) {
        const loginCheckRes = await loginCheck();
        if (loginCheckRes.status === 400) {
          throw new Error(loginCheckRes.msg);
        }
        return { subscribed: false, pendingInvoiceId: loginCheckRes?.pendingInvoiceId };
      }
    }
    if (response.data.Result != null) {
      return { ...response.data.Result, subscribed: true };
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getLoggedUserFromToken(authToken) {
  try {
    const response = await axios.get(LOGGED_USER + '/false', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (response.data.Result != null) {
      return { ...response.data.Result, subscribed: true };
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function authenticateUser(data) {
  const { token } = await generateToken(data);

  if (token != null) {
    // logUserActivity(UserActivityStatus.Login);
    const user = await getLoggedUser();
    return Promise.resolve(user);
  }
  return Promise.reject(new ApiError(response.data));
}

export async function registerUser(data) {
  return await axios.post(REGISTRATION, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject();
    }
    return { username: data.User_Username, password: data.User_PWD };
  });
}

export async function getCustomer() {
  return axios.post(`${API_BASE_URL}/customers`).then((response) => {
    if (response.status !== 200) {
      return Promise.reject();
    }
    return response.data;
  });
}

export async function checkBillingInfo(str) {
  if (str === 'noState') {
    return { status: true };
  }

  return axios
    .get(`${API_BASE_URL}/customers/checkCustomerAddress`)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject();
      }

      const { data } = response;

      if (data.AutomaticTaxStatus === 'supported') {
        return {
          status: true,
          message:
            "The customer is located in a country or state where you're collecting tax",
        };
      }

      if (data.AutomaticTaxStatus === 'not_collecting') {
        return {
          status: false,
          message:
            "The customer is located in a country or state where you're not registered to collect tax.\nAlso returned when automatic tax calculation is not supported in the customer's location.",
        };
      }
      if (data.AutomaticTaxStatus === 'unrecognized_location') {
        return {
          status: false,
          message:
            "The customer's location couldn't be determined.\nMake sure the provided address information is valid and supported in the customer's country.",
        };
      }

      if (data.AutomaticTaxStatus === 'failed') {
        return {
          status: false,
          message:
            "There was an error determining the customer's location.\nThis is usually caused by a temporary issue. Retrieve the customer to try again.",
        };
      }

      return { status: false, message: 'Bad request' };
    });
}

export async function updateRegisterUser(data) {
  const authToken = localStorage.getItem('authToken');
  const config = {
    method: 'PUT',
    url: UPDATE_REGISTRATION,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: data,
  };

  const response = await axios(config);

  if (!response.data.Success) {
    return Promise.reject();
  }

  return { username: data.User_Username, password: data.User_PWD };
}

export function recoverPassword(data) {
  return axios.post(RECOVER_PASSWORD, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Message;
  });
}

export function changePassword(data) {
  return axios.post(CHANGE_PASSWORD, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function validateData(data) {
  return axios.post(VALIDATE_DATA, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function resetPassword(data) {
  return axios.post(RESET_PASSWORD, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Message;
  });
}

export function getNewRegistrations(page, pageSize) {
  const authToken = localStorage.getItem('authToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      pageSize,
      page,
    },
  };

  return axios.get(NEW_REGISTRATIONS, config).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return {
      registrations: response.data.Items,
      totalRegistrations: response.data.TotalCount,
    };
  });
}

export function getConfirmedRegistrations() {
  return axios.get(CONFIRMED_REGISTRATIONS).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getRejectedRegistrations() {
  return axios.get(REJECTED_REGISTRATIONS).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function confirmRegistration(registrationId) {
  return axios
    .put(CONFIRM_REGISTRATION + '/' + registrationId, {
      headers: { 'Content-Type': 'text/plain' },
    })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }

      return registrationId;
    });
}

export function rejectRegistration(registrationId, description) {
  return axios
    .post(CONFIRM_REGISTRATION, {
      Id: registrationId,
      Description: description,
    })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return registrationId;
    });
}

export function getUsers(roleId, adminClientCompanyId) {
  return axios
    .get(
      `${USER}/${roleId}` +
      (adminClientCompanyId ? `/${adminClientCompanyId}/true` : '')
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getAdminClientOwnerClients(adminClientOwnerId) {
  return axios
    .get(ADMIN_CLIENT_OWNER + `/${adminClientOwnerId}/Clients`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getCalendarUsers(adminClientCompanyId) {
  return axios
    .get(
      adminClientCompanyId
        ? CALENDAR_USERS + `/${adminClientCompanyId}`
        : CALENDAR_USERS
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getPhonebookUsers(adminClientCompanyId) {
  return axios
    .get(
      adminClientCompanyId
        ? PHONEBOOK_USERS + `/${adminClientCompanyId}`
        : PHONEBOOK_USERS
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getUserBase(userId) {
  return axios.get(USER_BASE + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getAdminClientOwnersUsers() {
  return axios.get(ADMIN_CLIENT_OWNER_USERS).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function saveUser(user) {
  return axios.post(USER, user).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function activateUser(data) {
  return axios.put(USER, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function deleteUser(id) {
  return axios.delete(USER + '/' + id).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function checkUserName(id, userName) {
  return axios.get(USER + '/' + id + '/' + userName).then((response) => {
    return response.data.Success;
  });
}

export function checkEmail(id, email) {
  const data = { EmailAddress: email, UserId: id };
  return axios.post(USER_EMAIL, data).then((response) => {
    return { success: response.data.Success, message: response.data.Message };
  });
}

export function getCompany(companyId) {
  return axios.get(COMPANY + '/' + companyId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function saveCompany(company) {
  return axios.post(COMPANY, company).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getClients() {
  return axios.get(CLIENT).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getClientsForAdminClient(adminClientId) {
  return axios
    .get(
      CLIENTS_FOR_ADMIN_CLIENT +
      (adminClientId != null ? '/' + adminClientId : '')
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getAssistants() {
  return axios.get(ASSISTANT).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getAssistantsForClient(clientId, adminClientCompanyId = null) {
  return axios
    .get(
      ASSISTANT +
      '/' +
      clientId +
      (adminClientCompanyId ? '/' + adminClientCompanyId : '')
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getClientsForAssistant(assistantId) {
  return axios.get(CLIENT + '/' + assistantId).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getAvailableAssistants(clientId, adminClientCompanyId = null) {
  return axios
    .get(
      AVAILABLE_ASSISTANTS +
      '/' +
      clientId +
      (adminClientCompanyId ? '/' + adminClientCompanyId : '')
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function assignAssistantToClient(clientId, assistantId) {
  return axios
    .post(ASSIGN_ASSISTANT, { AssistantID: assistantId, ClientID: clientId })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function unassignAssistantFromClient(clientId, assistantId) {
  return axios
    .post(UNASSIGN_ASSISTANT, { AssistantID: assistantId, ClientID: clientId })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export async function getTaskAvailableUsersForCompany(
  adminClientCompanyId = null,
  clientId
) {
  const response = await axios.post(TASK_AVAILABLE_USERS_FOR_AC_COMPANY, {
    AdminClientCompanyId: adminClientCompanyId,
    ClientId: clientId,
  });
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return response.data.Result;
}

export async function createTask(task) {
  const response = await axios.post(TASK, task);
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return response.data.Result;
}

export async function saveTask(task) {
  const response = await axios.post(TASK, task);
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return { title: task.Title, description: task.Description };
}

export async function deleteTask(taskId) {
  const response = await axios.delete(TASK + '/' + taskId);
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return response.data.Result;
}

export function getTask(taskId) {
  return axios.get(TASK + '/' + taskId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getTasks(userId) {
  return axios.get(TASKS + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getGuestAvailableTasks(guestId) {
  return axios.get(GUEST_AVAILABLE_TASKS + '/' + guestId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export async function getTaskStatuses() {
  const response = await axios.get(TASK_STATUSES);
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return response.data.Result;
}

export function postTaskStatus(taskStatus) {
  return axios.post(TASK_STATUS, taskStatus).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return taskStatus.StatusID;
  });
}

export async function postTaskAssignees(taskAssignee) {
  const response = await axios.post(TASK_ASSIGNEE, taskAssignee);
  if (!response.data.Success) {
    return Promise.reject(new ApiError(response.data));
  } else return taskAssignee.AssigneeID;
}

export function getTaskComments(taskId) {
  return axios.get(TASK_COMMENTS + '/' + taskId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getLatestComments(taskId, commentId) {
  return axios
    .get(LATEST_COMMENTS + '/' + taskId + '/' + commentId)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else return response.data.Result;
    });
}

export function postTaskComment(taskComment) {
  return axios.post(TASK_COMMENTS, taskComment).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function deleteTaskComment(commentId) {
  return axios.delete(TASK_COMMENTS + '/' + commentId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getDocuments(taskId) {
  return axios.get(DOCUMENTS + '/' + taskId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getLatestDocuments(taskId, documentId) {
  return axios
    .get(LATEST_DOCUMENTS + '/' + taskId + '/' + documentId)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else return response.data.Result;
    });
}

export function getDocument(documentId, documentName) {
  axios({
    url: DOCUMENT + '/' + documentId,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    var fileDownload = require('react-file-download');
    fileDownload(new Blob([response.data]), documentName);
  });
}

export function postDocument(document) {
  return axios.post(DOCUMENT, document).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new Error(response.data.Result));
    } else return response.data.Result;
  });
}

export function deleteDocument(documentId) {
  return axios.delete(DOCUMENT + '/' + documentId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getGlobalSettings() {
  return axios.get(SETTINGS).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function saveGlobalSetting(data) {
  return axios.post(SETTINGS, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data;
  });
}

export function saveAvatar(image) {
  return axios.post(AVATAR, { image: image }).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else {
      //setSnackbarNotification('Avatar changed!')
      return image;
    }
  });
}

export function saveAvatarForUser(image, userId) {
  return axios
    .post(AVATAR, { image: image, userId: userId })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else {
        //setSnackbarNotification('Avatar changed!')
        return image;
      }
    });
}

export function getChatMessages(userId, loggedUser) {
  return axios.get(CHAT_MESSAGES + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else {
      return {
        chatGroups: response.data.Result,
        loggedUser,
      };
    }
  });
}
export function getChatRoomMessages(chatRoomId, lastId, messagesCount) {
  return axios
    .get(`${CHAT_ROOM_MESSAGES}/${chatRoomId}/${lastId}/${messagesCount}`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else return response.data.Result;
    });
}

export function getOverseenChatGroups(userId) {
  return axios.get(`${CHAT_GROUPS_OVERSEEN}/${userId}`).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getOverseenChatRoomMessages(
  roomId,
  messagesCount = INTIAL_OVERSEEN_MESSAGE_COUNT_FETCH
) {
  return axios
    .post(CHAT_ROOM_MESSAGES_OVERSEEN, {
      ChatRoomID: roomId,
      MessagesCount: messagesCount,
    })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getOverseenAdditinalChatRoomMessages(
  roomId,
  lastChatMessageId
) {
  return axios
    .get(
      `${CHAT_ROOM_MESSAGES_OVERSEEN_ADDITIONAL}/${roomId}/${lastChatMessageId}`
    )
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getGuestsForTask(taskId) {
  return axios.get(GUEST_TASK + '/' + taskId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getExistingGuests(taskId) {
  return axios.get(GUEST_EXISTING + '\\' + taskId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getGuestsForUser(userId) {
  return axios.get(GUEST_USER + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function addNewGuestToTask(
  firstName,
  lastName,
  email,
  taskId,
  guestUserId,
  adminClientCompanyId = null
) {
  return axios
    .post(GUEST_TASK, {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      TaskID: taskId,
      GuestID: guestUserId === undefined ? null : guestUserId,
      adminClientCompanyId: adminClientCompanyId,
    })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else return response.data.Result;
    });
}

export function deleteGuestFromTask(guestId, taskId) {
  return axios
    .post(DELETE_GUEST_TASK, { GuestID: guestId, TaskID: taskId })
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      } else return response.data.Result;
    });
}

export async function sendInviteEmail(data) {
  try {
    const res = await axios.post(CREATE_USER_WITH_REGISTRATION_LINK, data);
    return res;
  } catch (error) {
    return error.response;
  }
}
export async function resendRegistrationLink(data) {
  try {
    const res = await axios.post(RESEND_REGISTRATION_LINK, data);
    return res;
  } catch (error) {
    return error.response;
  }
}
export async function getEmailInvitations(companyId) {
  try {
    const response = await axios.get(GET_EMAIL_INVITATIONS, {
      params: {
        AdminClientCompanyId: companyId,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function registerUserWithLink(data) {
  try {
    const res = await axios.post(REGISTER_USER_WITH_LINK, data);
    return res.data;
  } catch {
    throw new Error('Error occured on server side!');
  }
}

export function createUser(data) {
  return axios
    .post(CREATE_USER, data)
    .then((res) => {
      return "User '" + data.User_Username + "' has been successfully created!";
    })
    .catch(() => {
      throw new Error('Error occured on server side!');
    });
}

export function getSpecificUser(userId) {
  return axios.get(SPECIFIC_USER + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return response.data.Result;
  });
}

export function getUserSettings(userId) {
  return axios.get(USER_SETTINGS + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function insertUserSettings(data) {
  return axios.post(USER_SETTINGS, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return data;
  });
}

export function updateUserSettings(data) {
  return axios.put(USER_SETTINGS, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    } else return data;
  });
}

export function getNotificationFrequencies() {
  return axios.get(NOTIFICATION_FREQUENCIES).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function logUserActivity(userActivityTypeId) {
  //return Promise.resolve();
  return axios
    .put(USER_ACTIVITY + '/' + userActivityTypeId)
    .then((response) => {
      if (!response.data.Success) {
        //return Promise.reject(new LogActivityError(response.data));
        //return Promise.reject(new ApiError(response.data));
        //TODO:Error handling after logging user activity, when token is expired, or user is deactivated
        return Promise.resolve(); //Because token expiration case, otherwise it will crash app
      } else return response.data.Result;
    })
    .catch(() => { });
}

export function getCalendarEntryTypes() {
  return axios.get(CALENDAR_ENTRY_TYPES).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCalendarEntryReminderTime() {
  return axios.get(CALENDAR_ENTRY_REMINDER_TIME).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCalendarEntryRecurrences() {
  return axios.get(CALENDAR_ENTRY_RECURRENCES).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCalendarEntries(userId, date, adminClientCompanyId) {
  const data = {
    DateTimeComplete: moment(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    UserID: userId,
    AdminClientCompanyID: adminClientCompanyId, 
  };

  return axios.post(CALENDAR_ENTRIES, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function saveCalendarEntry(data) {
  return axios.post(CALENDAR_ENTRY, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function deleteCalendarEntry(id) {
  return axios.delete(CALENDAR_ENTRY + '/' + id).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCalendarMonthlyAvailability(
  userId,
  year,
  month,
  adminClientCompanyId
) {
  const data = {
    UserID: userId,
    Year: year,
    Month: month,
    AdminClientCompanyID: adminClientCompanyId,
  };

  return axios.post(CALENDAR_MONTHLY_AVAILABILITY, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getAdminClientCompanyWithOwner(adminClientCompanyid) {
  return axios
    .get(ADMIN_CLIENT_COMPANY + `/${adminClientCompanyid}/AdminClientOwner`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getContacts(userId) {
  return axios.get(PHONEBOOK_CONTACTS + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getAdminClientCompanyContacts(adminClientCompanyId) {
  return axios
    .get(PHONEBOOK_AC_COMPANY_CONTACTS + '/' + adminClientCompanyId)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function deleteContacts(data, userId, adminClientCompanyId) {
  return axios.post(PHONEBOOK_CONTACTS_DELETE, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return { userId, adminClientCompanyId };
  });
}

export function getContactDetailTypes() {
  return axios.get(PHONEBOOK_CONTACT_DETAIL_TYPES).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function saveContact(value) {
  return axios.post(PHONEBOOK_CONTACT, value).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return {
      userId: value.UserID,
      adminClientCompanyId: value.AdminClientCompanyId,
    };
  });
}

export function deleteContact(id, userId, adminClientCompanyId) {
  return axios.delete(PHONEBOOK_CONTACT + '/' + id).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return { userId, adminClientCompanyId };
  });
}

export function importContacts(data, userId) {
  return axios
    .post(PHONEBOOK_CONTACTS + '/' + userId, data)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getPushOnSettings(userId) {
  return axios.get(PUSH_ON_SETTINGS + '/' + userId).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getNotificationData() {
  return axios.get(NOTIFICATION_DATA).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function savePushOnSettingsForClient(data) {
  return axios.post(PUSH_ON_CLIENT_DATA, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }

    return response.data.Result;
  });
}

export function getAdminNotificationData() {
  return axios.get(ADMIN_NOTIFICATION_DATA).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function subscribeToTaskNotifications(data) {
  return axios
    .post(`${PUSH_ON_TASK}/${data.userId}/${data.clientId}/${data.taskId}`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return data;
    });
}

export function unsubscribeFromTaskNotifications(data) {
  return axios
    .delete(`${PUSH_ON_TASK}/${data.userId}/${data.clientId}/${data.taskId}`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return data;
    });
}

export function getTaskDocumentNotificationData(taskId) {
  return axios
    .get(`${TASK_DOCUMENT_NOTIFICATION_DATA}/${taskId}`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getTaskCommentNotificationData(taskId) {
  return axios
    .get(`${TASK_COMMENT_NOTIFICATION_DATA}/${taskId}`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function getTaskNotificationData(taskId) {
  return axios.get(`${TASK_NOTIFICATION_DATA}/${taskId}`).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCalendarNotificationData() {
  return axios.get(CALENDAR_NOTIFICATION_DATA).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function setTaskCommentsSeen(data) {
  return axios.put(SET_TASK_COMMENTS_SEEN, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function setCalendarEntrySeen(data) {
  return axios.put(CALENDAR_ENTRY_SEEN, data).then((response) => {
    if (!response.data.Success) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.Result;
  });
}

export function getCreateUsersLimit() {
  return axios.get(CREATE_USER_LIMIT).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function uploadFile(selectedFile, roomId, type) {
  return axios
    .post(`${API_BASE_URL}/Chat/SendMediaMessage`, selectedFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
        RoomID: roomId,
        MediaType: type,
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function getAdminUsersLimit(userId) {
  return axios.get(`${CREATE_USER_LIMIT}/${userId}`).then((response) => {
    if (response.statusText !== 'OK') {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function getChatNotificationData() {
  return axios
    .get(`${API_BASE_URL}/Notification/Data/Chat`)
    .then((response) => {
      if (!response.data.Success) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    });
}

export function removeService(subscriptionItemId) {
  return axios
    .post(`${API_BASE_URL}/subscriptions/cancelsubscriptionitem`, {
      SubscriptionItemId: subscriptionItemId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function addNewSubscriptionItem(priceId) {
  return axios
    .post(`${API_BASE_URL}/subscriptions/addsubscriptionitem`, {
      PriceId: priceId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function upgradeUserPackage(priceId) {
  return axios
    .post(`${API_BASE_URL}/subscriptions/upgradesubscription`, {
      NewPriceId: priceId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function downgradeUserPackage(priceId) {
  return axios
    .post(`${API_BASE_URL}/subscriptions/downgradesubscription`, {
      NewPriceId: priceId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function cancelAccount() {
  return axios
    .post(`${API_BASE_URL}/subscriptions/cancelsubscription`)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function reactivateAccount() {
  return axios
    .post(`${API_BASE_URL}/subscriptions/reactivatesubscription`)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function markInvoiceAsPaid(invoiceId) {
  return axios
    .post(`${API_BASE_URL}/invoices/markinvoiceaspaid`, {
      InvoiceId: invoiceId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data;
    });
}

export function getStripeCustomer() {
  return axios.get(GET_STRIPE_CUSTOMER).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function getPaymentMethods() {
  return axios.get(GET_PAYMENT_METHODS).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data.data;
  });
}

export function attachPaymentMethod(id) {
  return axios.post(ATTACH_PAYMENT_METHOD(id)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function deletePaymentMethod(id) {
  return axios.delete(DELETE_PAYMENT_METHOD(id)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function changeDefaultPaymentMethod(id) {
  return axios.post(CHANGE_DEFAULT_PAYMENT_METHOD(id)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}

export function addCoupon(id) {
  return axios.post(ADD_COUPON(id)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new ApiError(response.data));
    }
    return response.data;
  });
}