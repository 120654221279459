import { CREATE_CHAT_GROUP, DELETE_CHAT_GROUP, FORWARD_MESASGE, GET_USER_CHATROOMS, USER_DATA } from '../api-routes';

import { API_BASE_URL } from '../../pages/Products/api';
import { ApiError } from '../../common/ApiError';
import axios from 'axios';

export const getChatRoomData = async (chatRoomID) => {
  return await axios
    .get(`${API_BASE_URL}/ChatRoom/${chatRoomID}/Users`)
    .then((x) => x.data.Result)
    .catch((x) => console.log(x));
};

export async function getUserData(id) {
  return await axios
    .get(USER_DATA(id))
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    })
    .catch((err) => Promise.reject(new ApiError(err)));
}

export async function getUserChatRooms(id) {
  return await axios
    .get(GET_USER_CHATROOMS(id))
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    })
    .catch((err) => Promise.reject(new ApiError(err)));
}

export async function shareMessage(messageId, roomId) {
  return await axios
    .post(FORWARD_MESASGE, {
      ChatMessageID: messageId,
      RoomID: roomId,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    })
    .catch((err) => Promise.reject(new ApiError(err)));
}

export async function createChatGroup(name, userIds) {
  return await axios
    .post(CREATE_CHAT_GROUP, {
      ChatRoomName: name,
      UserIds: userIds,
    })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    })
    .catch((err) => Promise.reject(new ApiError(err)));
}

export async function deleteChatGroup(groupId) {
  return await axios
    .delete(DELETE_CHAT_GROUP(groupId))
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(new ApiError(response.data));
      }
      return response.data.Result;
    })
    .catch((err) => Promise.reject(new ApiError(err)));
}
