import Validator from 'validator';
import isEmpty from 'is-empty';
import { regexPassword, regexUsername } from '../constants';

export function validateChangePassword(data) {
  let errors = {};

  if (data.oldPassword === null || Validator.isEmpty(data.oldPassword)) {
    errors.oldPassword = 'Old password is required';
  }

  const passwordValidationResult = validatePassword(data.newPassword);

  if (!passwordValidationResult.valid) {
    errors.newPassword = passwordValidationResult.message;
  }

  if (
    data.newPassword !== null &&
    !Validator.isEmpty(data.newPassword) &&
    data.oldPassword === data.newPassword
  ) {
    errors.newPassword = "New password can't be same as old one";
  }

  if (data.repeatPassword === null || Validator.isEmpty(data.repeatPassword)) {
    errors.repeatPassword = 'Repeat password is required';
  } else if (!Validator.equals(data.newPassword, data.repeatPassword)) {
    errors.repeatPassword = 'Passwords must match';
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}

export function validatePassword(password) {
  let message = null;

  if (password === null || Validator.isEmpty(password)) {
    message = 'New password is required';
  } else if (!regexPassword.test(password)) {
    message =
      'Complexity criteria not met (It must contain at least 7 characters, one small letter, one big letter, one character and one number)';
  }

  return {
    message,
    valid: message ? false : true,
  };
}

export function validateUsername(username) {
  let message = null;

  if (username === null || Validator.isEmpty(username)) {
    message = 'Username is required';
  } else if (!regexUsername.test(username)) {
    message =
      'Username must be atleast 7 characters and can only contain letters, numbers, underscores, and hyphens. It cannot start or end with an underscore or hyphen, and cannot have consecutive underscores or hyphens.';
  }

  return {
    message,
    valid: message ? false : true,
  };
}
